import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const guest = (to, from, next) => {
  localStorage.removeItem("authToken");
  if (!localStorage.getItem("authToken")) {
    localStorage.removeItem("authToken");
    return next(next);
  } else {
    return next("/");
  }
};
// const auth = (to, from, next) => {
  
//   if (localStorage.getItem("authToken") && localStorage.getItem("permissions") != "1") {
//     if(localStorage.getItem("permissions") == 2){
//       return next(); 
//     }
//   }else {
//     localStorage.removeItem("authToken");
//     return next("/login");
//   }  
// };
const auth = (to, from, next) => {
  const authToken = localStorage.getItem("authToken");
  const permissions = localStorage.getItem("permissions");

  if (authToken && permissions) {
    if (permissions === "1" || permissions === "2") { // Allow both admin (permission level 1) and user (permission level 2)
      return next();
    }
  }

  // If not authenticated or doesn't have appropriate permissions, redirect to login
  localStorage.removeItem("authToken");
  next("/login");
};
// const simpleAuth = (to, from, next) => {
//   if (localStorage.getItem("authToken") && localStorage.getItem("permissions") !== '1') {
//       return next(next);
//   }
//   else {
//     localStorage.removeItem("authToken");
//     return next("/login");
//   }
// };
const adminAuth = (to, from, next) => {
  if (localStorage.getItem("authToken") && localStorage.getItem("permissions") == 1) {
      return next(next);
  }
  else {
    localStorage.removeItem("authToken");
    return next("/login");
  }
}; 
const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: auth,
    component: () => import(/* webpackChunkName: "Home" */ "../views/Home.vue"),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Auth/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Register" */ "../views/Auth/Register.vue")
  },
  {
    path: "/add_store",
    name: "AddStore",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Add Store" */ "../views/AddStore.vue")
  },
  {
    path: "/instruction",
    name: "InstructionToAddStore",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Add Store" */ "../views/AddStoreInstruction.vue")
  },
  {
    path: "/confirm-order/:orderId?",
    name: "ConfirmOrder",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Add Store" */ "../views/ConfirmedOrder.vue")
  },
  {
    path: "/orders",
    name: "Home",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Home.vue")
  },
  {
    path: "/customers",
    name: "Customers",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Customers.vue")
  },
  {
    path: "/RefundOrders",
    name: "RefundOrders",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/RefundOrders.vue")
  },
  {
    path: "/Subscriptions",
    name: "Subscriptions",
    beforeEnter: auth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Subscriptions.vue")
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    beforeEnter: adminAuth,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Subscriptions.vue")
  },
  {
    path: "/createSubscriptions",
    name: "CreateSubscriptions",
    beforeEnter: adminAuth,
    component: () =>
      import(/* webpackChunkName: "CreateEventType" */ "../views/CreateSubscriptions.vue"),
  },
  {
    path: "/verification-email",
    name: "VerificationEmail",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Verification_email"*/ "../views/Auth/VerificationEmail.vue"),
  },
  {
    path: "/verified-email",
    name: "VerifiedEmail",
    beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "verified-email" */ "../views/Auth/VerifiedEmail.vue"),
  },
  {
    path: "/verify/:hash",
    name: "Verify",
    // beforeEnter: auth,
    beforeEnter: guest,
    props: true,
    component: () =>
      import(/* webpackChunkName: "Verify" */ "../views/Auth/Verify.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswrod",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/Auth/ForgotPassword/Forgot.vue"
      ),
  },
  
  {
    path: "/verify-email",
    name: "VerifyEmail",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "VerifyEmail" */ "../views/Auth/ForgotPassword/VerifyEmail.vue"
      ),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "NewPassword" */ "../views/Auth/ForgotPassword/NewPassword.vue"
      ),
  },
  {
    path: "/password-update",
    name: "PasswordUpdate",
    beforeEnter: guest,
    component: () =>
      import(
        /* webpackChunkName: "PasswordUpdate" */ "../views/Auth/ForgotPassword/PasswordUpdate.vue"
      ),
  },
 
  {
    path: "/notification",
    name: "notification",
    // beforeEnter: guest,
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Notificatioins.vue")
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
export default router;